import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/components/home/articleHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/components/home/desktopMode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/components/home/mobileMode.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/components/home/pricingHomeClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/slip-website/slip-website/src/app/components/swiperComponents.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/icons/socialIcon/API.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/icons/socialIcon/LINE.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/slip-website/slip-website/src/app/icons/socialIcon/LINEAT.svg");
