'use client'

import { memo } from 'react'
import HomeLeft from '@/app/icons/homelefts.svg'
import Image from 'next/image'
import classNames from 'classnames'
import type IDataHomeHead from '@/app/models/homeHead'
import ArrowHomeIcon from '@/app/icons/arrowHomeIcon'

function HomeCardComponent({
  data,
  index,
  totalLength
}: {
  data: IDataHomeHead
  index: number
  totalLength: number
}) {
  return (
    <div
      className={classNames(
        '',
        index !== 0 && index !== totalLength - 1 ? 'pt-4 pl-[44px]' : index === 0 ? '' : 'pt-4'
      )}
    >
      <div className="relative rounded-full w-full max-w-[556px] h-[107px] bg-white-true bg-opacity-50 drop-shadow-homeBoxContent">
        <div className="rounded-full bg-gradient-to-r w-[106px] h-[107px] absolute top-0 left-0 from-primary-ocean-blue-base to-secondary-violeta-base">
          <div className="h-full flex flex-col justify-center items-center">
            <div>
              <Image
                src={data.image}
                alt={data.imageAlt}
                width={50}
                height={50}
                priority={index < 2}
                loading={index < 2 ? 'eager' : 'lazy'}
                className="w-auto h-auto"
                sizes="50px"
              />
            </div>
            <h2 className="text-white-true text-sukhumvit16 font-sukhumvit font-sukhumvitw700">
              {data.imageString}
            </h2>
          </div>
        </div>
        <div className="flex justify-between w-full pl-[107px] h-[107px]">
          <div className="w-full pl-3 pt-4">
            <h2 className="pb-1 text-sukhumvit20 font-sukhumvit font-sukhumvitw700 text-transparent bg-gradient-to-r from-primary-ocean-blue-700 to-secondary-violeta-700 bg-clip-text">
              {data.title}
            </h2>
            <p className="text-chatthai16 font-chatthai font-chatthaiw400 text-neutral-gray-600 line-clamp-2">
              {data.content}
            </p>
          </div>
          <div className="pl-3 pr-4 w-[56px] flex items-center">
            <ArrowHomeIcon alt={`arrowRightColor-${index}`} classname="" />
          </div>
        </div>
      </div>
    </div>
  )
}

const HomeCard = memo(HomeCardComponent)
HomeCard.displayName = 'HomeCard'

function DesktopModeHeadPromod({ dataHome }: { dataHome: IDataHomeHead[] | [] }) {
  if (!dataHome?.length) return null

  return (
    <div className="w-full h-full flex">
      <div className="w-[48.18%]">
        <Image
          src={HomeLeft}
          alt="HomeLeft"
          width={800}
          height={600}
          priority
          quality={85}
          sizes="(max-width: 768px) 100vw, 48vw"
          className="w-full h-auto object-cover"
        />
      </div>
      <div className="w-[51.82%]">
        <div className="desktop:block laptop:block tabletHorizontal:block mobile:hidden">
          {dataHome.map((data, index) => (
            <HomeCard
              key={`home-card-${index}`}
              data={data}
              index={index}
              totalLength={dataHome.length}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(DesktopModeHeadPromod)
