'use client'

import classNames from 'classnames'
import PricingBoxPage from '../pricingBox'
import PackageType from '../../constants/typePackage'
import IPackageModule, {
  IPackageExpire,
  IPackageTier,
  IShowPackageExpire
} from '../../models/packageModule'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import PricingContact from '../pricingContact'
import { Suspense, useEffect, useState } from 'react'
import { calculateExpireDateRange } from '@/util'
import Image from 'next/image'

interface IProps {
  packageModule: IPackageModule[] | []
  packageTier: IPackageTier[] | []
  packageExpire: IPackageExpire[] | []
}

export default function PricingHomeClient(props: IProps) {
  const [allPackage, setAllPackage] = useState<IPackageModule[]>([])
  const [isClient, setIsClient] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [dataReady, setDataReady] = useState(false)
  const [expire, setExpire] = useState<IPackageExpire[]>([])
  const [packageGroup, setPackageGroup] = useState<IPackageTier[]>([])
  const [selectedExpire, setSelectedExpire] = useState<IPackageExpire>({
    id: 0,
    expireDate: 0
  })
  const [selectedTier, setSelectedTier] = useState<IPackageTier>({
    id: 0,
    name: ''
  })

  // สร้าง Skeleton Loader สำหรับ Slider
  const SliderSkeleton = () => (
    <div className="pt-3 flex justify-center overflow-x-auto gap-2 pb-4">
      {[1, 2, 3].map((item) => (
        <div
          key={item}
          className="min-w-[280px] h-[376px] bg-gray-100 animate-pulse rounded-lg flex-shrink-0"
        >
          <div className="h-24 rounded-t-lg bg-gray-200"></div>
          <div className="p-4 space-y-3">
            <div className="h-6 rounded-full bg-gray-200 w-3/4 mx-auto"></div>
            <div className="h-10 rounded-full bg-gray-200 w-1/2 mx-auto"></div>
            <div className="h-4 rounded-full bg-gray-200 w-5/6 mx-auto"></div>
            <div className="h-4 rounded-full bg-gray-200 w-4/6 mx-auto"></div>
            <div className="h-10 rounded-full bg-gray-200 w-4/5 mx-auto mt-8"></div>
          </div>
        </div>
      ))}
    </div>
  )

  // สร้าง Skeleton Loader สำหรับ Tab
  const TabSkeleton = () => (
    <div className="max-w-[656px] h-12 mx-auto rounded-full bg-gray-100 animate-pulse"></div>
  )

  const onShowPackage = (): IPackageModule[] => {
    const packageGroup = allPackage.filter((item) => {
      return item.packageExpireId === selectedExpire.id && item.packageGroupId === selectedTier.id
    })
    if (selectedTier.name === PackageType.BASIC) {
      const findPackFree = allPackage.find((item) => item.packageGroupId === 1)
      if (findPackFree) {
        packageGroup.unshift(findPackFree)
      }
    }
    selectedTier.name === PackageType.PREMIUM
      ? packageGroup.push({
          id: 0,
          name: '',
          price: 0,
          isPopular: false,
          pricePerSlip: 0,
          overPricePerSlip: 0,
          rawPrice: 0,
          rawPricePerSlip: 0,
          slipQuota: 0,
          discount: 0,
          packageGroup: 'custom',
          expireDate: 0,
          priceTopupDate: 0,
          packageExpireId: 0,
          packageGroupId: 0,
          packageTier: { id: 0, name: '' },
          packageExpire: { id: 0, expireDate: 0 }
        })
      : null
    return packageGroup
  }

  const onShowPackageExpire = (): IShowPackageExpire[] => {
    const getPackageGroup = allPackage.filter((item) => item.packageGroupId === selectedTier.id) // filter หาแพ็คเกจทั้งหมดที่เลือกอยู่
    const grouped = getPackageGroup.reduce((acc: { [key: string]: IPackageModule[] }, pkg) => {
      const groupKey = pkg.packageExpire.expireDate
      acc[groupKey] = acc[groupKey] || []
      acc[groupKey].push(pkg)
      return acc
    }, {})
    const result = Object.values(grouped)
    const highestDiscounts: IShowPackageExpire[] = result.map((group) => {
      const maxDiscount = group.reduce((max, pkg) => {
        return pkg.discount > max ? pkg.discount : max
      }, 0)
      return {
        id: group[0].packageExpireId,
        expireDate: group[0].packageExpire.expireDate,
        discount: maxDiscount
      }
    })

    return expire.map((item) => {
      const matchedExpire = highestDiscounts.find(
        (pkg) => pkg.expireDate === item.expireDate && pkg.id === item.id
      )
      if (matchedExpire) {
        return { ...item, discount: matchedExpire.discount }
      }
      return { ...item, discount: 0 }
    })
  }

  useEffect(() => {
    if (
      props.packageModule &&
      props.packageModule.length > 0 &&
      props.packageExpire &&
      props.packageExpire.length > 0 &&
      props.packageTier &&
      props.packageTier.length > 0
    ) {
      setAllPackage(props.packageModule)
      setExpire(props.packageExpire)
      setPackageGroup(props.packageTier)

      setSelectedExpire({
        id: props.packageExpire[0].id,
        expireDate: props.packageExpire[0].expireDate
      })

      setSelectedTier({
        id: props.packageTier[0].id,
        name: props.packageTier[0].name
      })

      setDataReady(true)

      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 400)

      return () => clearTimeout(timer)
    }
  }, [props])

  useEffect(() => {
    if (dataReady) {
      const forceTimer = setTimeout(() => {
        setIsLoading(false)
        // console.log('Force loading to false after timeout')
      }, 2000)

      return () => clearTimeout(forceTimer)
    }
  }, [dataReady])

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <div className="w-full desktop:px-0 mobile:px-[18px] pb-8 max-w-[1200px] mx-auto">
      {isClient ? (
        <div>
          <h2 className="w-full text-center desktop:text-sukhumvit28 font-sukhumvit font-sukhumvitw700 mobile:text-sukhumvit20 text-neutral-gray-900">
            เลือกแพ็กเกจที่เหมาะสมกับคุณ
          </h2>
          <h3 className="pt-2 pb-4 text-primary-ocean-blue-600 text-sukhumvit20 font-sukhumvitw700 font-sukhumvit w-full text-center">
            ไม่ว่าแพ็กเกจไหนคุณก็สามารถใช้งาน Slip2Go ได้ทุกบริการ
          </h3>

          <div className="py-2">
            {isLoading ? (
              <TabSkeleton />
            ) : (
              <div className="max-w-[656px] overflow-auto min-w-[284px] w-full bg-[#E7FFFF] desktop:h-[64px] tabletHorizontal:h-[64px] laptop:h-[64px] mobile:h-[64px] mx-auto rounded-full flex items-center first-line:gap-[32px] justify-evenly">
                {onShowPackageExpire().length > 0
                  ? onShowPackageExpire().map((data, indexMonth) => {
                      return (
                        <div
                          key={indexMonth}
                          className="w-full p-1 min-w-[108px] max-w-[180px] h-full tabletHorizontal:min-w-[182px] tabletHorizontal:max-w-[182px] laptop:min-w-[218px] laptop:max-w-[218px]"
                        >
                          <div
                            className={classNames(
                              'w-full h-full flex justify-center items-center border-2 rounded-full',
                              selectedExpire.id === data.id
                                ? 'border-primary-ocean-blue-base'
                                : 'hover:border-primary-ocean-blue-base hover:cursor-pointer border-transparent'
                            )}
                            onClick={(): void => {
                              setSelectedExpire({
                                id: data.id,
                                expireDate: data.expireDate
                              })
                              // setIsLoading(true)
                              // setTimeout(() => setIsLoading(false), 200)
                            }}
                          >
                            <div className="flex flex-col h-full justify-evenly">
                              <h3 className="text-primary-ocean-blue-600 desktop:text-sukhumvit16 text-sukhumvit14 font-sukhumvitw700 font-sukhumvit">
                                {calculateExpireDateRange(data.expireDate)}
                              </h3>
                              <p
                                className={classNames(
                                  data.discount
                                    ? 'block text-danger-orange-red-base font-sukhumvit font-sukhumvitw700 desktop:text-sukhumvit16 desktop:pt-0'
                                    : 'hidden'
                                )}
                              >
                                -{data.discount}%
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
            )}
          </div>
          <div>
            {isLoading ? (
              <TabSkeleton />
            ) : (
              <div className="w-full max-w-[656px] overflow-auto min-w-[284px] bg-[#E7FFFF] desktop:h-12 laptop:h-12 tabletHorizontal:h-12 mobile:h-9 mx-auto rounded-full flex items-center justify-evenly">
                <div className="flex items-center justify-evenly w-full gap-2">
                  {packageGroup.length > 0
                    ? packageGroup.map((packageName, indexPack) => {
                        return (
                          <h3
                            key={indexPack}
                            className={classNames(
                              'min-w-[102px] max-w-[150px] tabletHorizontal:min-w-[176px] tabletHorizontal:max-w-[176px] laptop:min-w-[213px] laptop:max-w-[213px] desktop:min-w-[213px] desktop:max-w-[213px] desktop:h-11 laptop:h-11 tabletHorizontal:h-11 mobile:h-8 flex justify-center items-center font-sukhumvit font-sukhumvitw700 text-sukhumvit16 truncate whitespace-nowrap',
                              selectedTier.id === packageName.id
                                ? 'bg-primary-ocean-blue-base text-white-true rounded-full'
                                : 'hover:bg-primary-ocean-blue-base hover:cursor-pointer hover:rounded-full hover:text-white-true text-neutral-gray-700'
                            )}
                            onClick={(): void => {
                              setSelectedTier({
                                id: packageName.id,
                                name: packageName.name
                              })
                              // setIsLoading(true)
                              // setTimeout(() => setIsLoading(false), 200)
                            }}
                          >
                            {packageName.name.toUpperCase()}
                          </h3>
                        )
                      })
                    : null}
                </div>
              </div>
            )}
          </div>
          <Suspense fallback={<div className="h-[376px]">กำลังโหลด...</div>}>
            <div
              className={classNames(
                onShowPackage().length < 5
                  ? 'desktop:flex laptop:flex tabletHorizontal:block mobile:block desktop:justify-center laptop:justify-center tabletHorizontal:justify-center'
                  : '',
                'pt-3'
              )}
            >
              {isLoading ? (
                <SliderSkeleton />
              ) : onShowPackage() && onShowPackage().length > 0 ? (
                <Splide
                  options={{
                    rewind: true,
                    perPage: 1,
                    perMove: 1,
                    gap: '0.5rem',
                    autoWidth: true,
                    pagination: false,
                    arrows: false
                  }}
                  aria-label="Price Slider"
                >
                  {onShowPackage().map((item: IPackageModule, index: number) => {
                    const checkHaveDiscount =
                      onShowPackage().reduce((prev, curr) => prev + curr.discount, 0) > 0
                        ? true
                        : false
                    return (
                      <SplideSlide key={index}>
                        {item.packageGroup === 'custom' ? (
                          <PricingContact promotionShow={checkHaveDiscount} />
                        ) : (
                          <PricingBoxPage item={item} promotionShow={checkHaveDiscount} />
                        )}
                      </SplideSlide>
                    )
                  })}
                </Splide>
              ) : (
                <h3 className="pt-[52px] pb-5 flex flex-col items-center">
                  <Image
                    src="/package/new_package.png"
                    alt="New Package"
                    width={242}
                    height={206}
                  />
                  <p className="text-primary-ocean-blue-600 font-sukhumvit text-sukhumvit24 font-sukhumvitw700">
                    แพ็คเกจใหม่จะมาในเร็วๆนี้
                  </p>
                </h3>
              )}
            </div>
          </Suspense>
        </div>
      ) : (
        // สร้าง skeleton UI เมื่อ isClient ยังเป็น false
        <div className="flex flex-col justify-center animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-2/3 mx-auto"></div>
          <div className="h-6 bg-gray-200 rounded w-3/4 mx-auto"></div>
          <div className="h-12 bg-gray-200 rounded-full w-full max-w-[656px] mx-auto mt-8"></div>
          <div className="h-12 bg-gray-200 rounded-full w-full max-w-[656px] mx-auto mt-4"></div>
          <SliderSkeleton />
        </div>
      )}
    </div>
  )
}
