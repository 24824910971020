// components/PartnerSlider.tsx
'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, Pagination } from 'swiper/modules'
import Image from 'next/image'
import IDataPartner from '../models/partnerModel'

interface PartnerSliderProps {
  partners: IDataPartner[]
  groupedPartners?: IDataPartner[][]
  view: 'mobile' | 'laptop' | 'desktop'
}

export default function PartnerSlider({ partners, groupedPartners, view }: PartnerSliderProps) {
  if (view === 'mobile') {
    return (
      <Swiper
        slidesPerView={1}
        grid={{
          rows: 1
        }}
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          579: {
            slidesPerView: 2,
            spaceBetween: 10
          }
        }}
        modules={[Grid, Pagination]}
        className="mySwiper !p-2 !pt-0 !pb-[50px]"
      >
        {partners.map((partner, numberPartner) => (
          <SwiperSlide className="rounded-lg" key={numberPartner}>
            <div className="p-2 w-full h-full max-h-[300px] flex flex-col items-center justify-center rounded-lg">
              <div className="w-[127px] h-[127px] relative flex items-center justify-center">
                <Image
                  width={127}
                  height={127}
                  src={partner.imageUrl}
                  alt={'partner image'}
                  className="rounded-full object-cover"
                />
              </div>
              <div className="text-center w-full">
                <div className="!min-h-[96px] py-2">
                  <h4 className="text-neutral-gray-700 line-clamp-3 text-sukhumvit16 font-chatthai font-sukhumvitw400 max-h-[57px]">
                    {partner.review}
                  </h4>
                </div>
                <h6 className="min-h-[56px] pt-2 text-primary-ocean-blue-600 text-sukhumvit16 font-sukhumvit font-sukhumvitw400">
                  - {partner.shopOwnerName} / {partner.shopName} -
                </h6>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  if (view === 'laptop' || view === 'desktop') {
    return (
      <Swiper
        slidesPerView={1}
        grid={{
          rows: 1,
          fill: 'row'
        }}
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        modules={[Grid, Pagination]}
        className="mySwiper"
      >
        {groupedPartners?.map((partnerGroup, numberPartnerLaptop) => (
          <SwiperSlide
            key={numberPartnerLaptop}
            className="!bg-transparent !grid grid-cols-2 gap-2"
          >
            {partnerGroup.map((partner: IDataPartner, partnerIndex: number) => {
              return (
                <div
                  key={partnerIndex}
                  className="bg-white mb-2 rounded-rounded drop-shadow-homeBoxContent"
                >
                  <div className="desktop:p-4 laptop:p-4 mobile:p-2 tabletHorizontal:p-2 w-full tabletHorizontal:h-[150px] flex gap-5 desktop:flex-row laptop:flex-row tabletHorizontal:flex-col mobile:flex-col items-center justify-center">
                    <div className="w-[118px] h-[118px] relative">
                      <Image
                        src={partner.imageUrl}
                        alt="partner image"
                        className="rounded-full !object-contain"
                        priority
                        quality={100}
                        sizes="100px"
                        fill
                      />
                    </div>
                    <div className="text-center w-[402px]">
                      <div className="py-2">
                        <h4 className="text-neutral-gray-700 line-clamp-3 break-words text-sukhumvit16 font-sukhumvit font-sukhumvitw500 max-h-[57px]">
                          {partner.review}
                        </h4>
                      </div>
                      <h6 className="pt-4 text-primary-ocean-blue-600 text-MediumBase text-sukhumvit16 font-sukhumvit font-sukhumvitw500 line-clamp-2 break-words">
                        - {partner.shopOwnerName} / {partner.shopName} -
                      </h6>
                    </div>
                  </div>
                </div>
              )
            })}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  return null
}
