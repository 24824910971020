import Image from 'next/image'
import logo from './arrowHomeIcon.svg'
import classNames from 'classnames'

export default function ArrowHomeIcon({ classname, alt }: { classname?: string; alt?: string }) {
  return (
    <div className="w-full h-full flex items-center justify-center pl-6">
      <Image
        src={logo.src}
        alt={alt || 'arrowHomeIcon'}
        className={classNames('w-auto h-auto', classname)}
        priority
        width={19}
        height={19}
        quality={85}
        loading={'eager'}
        sizes="19px"
      />
    </div>
  )
}
