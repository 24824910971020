import Image from 'next/image'
import Link from 'next/link'
import IArticle from '../models/article'
import classNames from 'classnames'

export default function PageBlogHome({ articleList }: { articleList: IArticle[] }) {
  return (
    <div className="px-5 pb-8 max-w-[1200px] w-full mx-auto">
      <div className="desktop:pt-[30px] pt-5 w-full text-center mobile:hidden tabletHorizontal:hidden laptop:block desktop:block">
        <h4 className="text-sukhumvit28 font-sukhumvit font-sukhumvitw700">ข่าวสาร</h4>
        <div>
          <div className="py-4 h-full grid desktop:grid-cols-3 tabletHorizontal:grid-cols-2 laptop:grid-cols-3 mobile:grid-cols-1 gap-4 mobile:gap-0 laptop:gap-2 tabletHorizontal:gap-2">
            {articleList &&
              articleList.length > 0 &&
              articleList.map((article: IArticle, index: number) => {
                return (
                  <div
                    key={index}
                    className="border border-neutral-gray-200 bg-white/70 backdrop-saturate-0 rounded-rounded desktop:max-h-full laptop:max-h-[284px] h-full p-4"
                  >
                    <div className="w-full flex justify-center items-center">
                      <Image
                        width={347}
                        height={240}
                        className="w-full desktop:!h-[240px] laptop:!h-[140px] !h-[76px]"
                        quality={85}
                        loading="eager"
                        blurDataURL="data:image/jpeg"
                        src={article.imageUrl}
                        alt={`Blog-${index}`}
                      />
                    </div>
                    <div className="py-2 text-start">
                      <h6 className="text-primary-ocean-blue-600 text-sukhumvit16 font-sukhumvit font-sukhumvitw600 truncate">
                        {article.title}
                      </h6>
                    </div>
                    <div className="pb-2 truncate">
                      <h6 className="text-neutral-gray-500 font-sukhumvit text-sukhumvit16 font-sukhumvitw400">
                        {article.description}
                      </h6>
                    </div>
                    <Link href={`/blog/${article.id}-${article.title}`}>
                      <button
                        type="button"
                        className="w-full rounded-rounded py-2 group justify-center flex hover:bg-primary-ocean-blue-600 items-center border border-primary-ocean-blue-600"
                      >
                        <h6 className="text-primary-ocean-blue-600 group-hover:text-white font-sukhumvit font-sukhumvitw700 text-sukhumvit16 pr-1">
                          อ่านต่อ
                        </h6>
                        <svg
                          className="group-hover:fill-white fill-primary-ocean-blue-base"
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.253768 0.653769C0.458793 0.448744 0.791205 0.448744 0.99623 0.653769L3.97123 3.62877C4.17625 3.83379 4.17625 4.16621 3.97123 4.37123L0.99623 7.34623C0.791204 7.55126 0.458793 7.55126 0.253767 7.34623C0.0487422 7.14121 0.0487422 6.80879 0.253767 6.60377L2.85754 4L0.253768 1.39623C0.0487424 1.19121 0.0487424 0.858794 0.253768 0.653769Z"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      {/* mobile section */}
      <div className="desktop:hidden pt-5 laptop:hidden tabletHorizontal:block mobile:block tabletHorizontal:h-[336px] mobile:h-[336px]">
        <div className="text-sukhumvit20 font-sukhumvitw600 font-sukhumvit text-neutral-900 w-full text-center">
          ข่าวสาร
        </div>
        {articleList &&
          articleList.length > 0 &&
          articleList.map((article: IArticle, index: number) => {
            return (
              <div key={index} className={classNames('pt-3 w-full')}>
                <div className="flex w-full items-center p-2 border border-neutral-gray-200 rounded-wrapAround-1 max-h-[92px] h-full">
                  <div className="flex w-full flex-col tabletHorizontal:max-w-full tabletHorizontal:min-w-[284px] mobile:min-w-[284px]">
                    <div className="flex w-full items-center">
                      <Image
                        width={76}
                        height={76}
                        className="w-[76px] h-[76px] rounded-md"
                        quality={85}
                        loading="eager"
                        blurDataURL="data:image/jpeg"
                        src={article.imageUrl}
                        alt={`blog-mobile-image${article.id}`}
                      />
                      <div className="pl-1 w-full">
                        <div className="w-full">
                          <span className="mb-1 tabletHorizontal:line-clamp-2 mobile:line-clamp-1 w-full text-sukhumvit14 font-sukhumvitw700 text-primary-ocean-blue-600">
                            {article.title}
                          </span>
                          <span className="tabletHorizontal:max-h-[52px] tabletHorizontal:line-clamp-2 mobile:line-clamp-1 text-sukhumvit1 font-sukhumvitw400 text-neutral-gray-700">
                            {article.description}
                          </span>
                        </div>
                        <div className="w-full pl-1 pt-1 mobile:flex justify-end tabletHorizontal:hidden">
                          <Link href={`/blog/${article.id}-${article.title}`}>
                            <button
                              type="button"
                              className="flex items-center justify-center rounded-rounded w-[78px] h-[28px] border border-primary-ocean-blue-base"
                            >
                              <span className="text-primary-ocean-blue-base text-sukhumvit14 font-sukhumvit font-sukhumvitw700 pr-1">
                                อ่านต่อ
                              </span>
                              <svg
                                className="fill-primary-ocean-blue-base"
                                width="5"
                                height="8"
                                viewBox="0 0 5 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.253768 0.653769C0.458793 0.448744 0.791205 0.448744 0.99623 0.653769L3.97123 3.62877C4.17625 3.83379 4.17625 4.16621 3.97123 4.37123L0.99623 7.34623C0.791204 7.55126 0.458793 7.55126 0.253767 7.34623C0.0487422 7.14121 0.0487422 6.80879 0.253767 6.60377L2.85754 4L0.253768 1.39623C0.0487424 1.19121 0.0487424 0.858794 0.253768 0.653769Z"
                                />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[78px] w-full flex justify-end pl-1 pt-1 mobile:hidden tabletHorizontal:flex">
                    <Link href={`/blog/${article.id}-${article.title}`}>
                      <button
                        type="button"
                        className="flex items-center justify-center rounded-rounded w-[78px] h-[28px] border border-primary-ocean-blue-base"
                      >
                        <span className="text-primary-ocean-blue-base pr-1">อ่านต่อ</span>
                        <svg
                          className="fill-primary-ocean-blue-base group-hover:fill-white"
                          width="5"
                          height="8"
                          viewBox="0 0 5 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.253768 0.653769C0.458793 0.448744 0.791205 0.448744 0.99623 0.653769L3.97123 3.62877C4.17625 3.83379 4.17625 4.16621 3.97123 4.37123L0.99623 7.34623C0.791204 7.55126 0.458793 7.55126 0.253767 7.34623C0.0487422 7.14121 0.0487422 6.80879 0.253767 6.60377L2.85754 4L0.253768 1.39623C0.0487424 1.19121 0.0487424 0.858794 0.253768 0.653769Z"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
